import { Vue, Options } from 'vue-class-component';
import { chevronForwardOutline, chevronForwardSharp } from 'ionicons/icons';
import fruttaMeClient from '@/core/api/fruttame-service';
import { mapState } from 'vuex';
import {
    IResult,
    PlantReferenceDTO,
    SupplierArchiveTransportDTO,
} from 'client-generator/generated-clients/client';
import { SET_APP_LOADING } from '@/core/store/action-types';
import { PAGE_SALES_ARCHIVE_DETAIL } from '@/core/router/pages';

@Options({
    name: 'SalesArchivePage',
    computed: {
        ...mapState(['currentPlant']),
    },
})
export default class SalesArchivePage extends Vue {
    chevronForwardOutline = chevronForwardOutline;
    chevronForwardSharp = chevronForwardSharp;
    salesArchiveList: SupplierArchiveTransportDTO[] = [];
    currentPlant!: PlantReferenceDTO | null;

    async mounted() {
        await this.fetchSalesArchive();
    }
    formatDate(dateString: string) {
        const _date = new Date(dateString);
        if (isNaN(_date.getTime())) return '-';
        return _date.toLocaleString(undefined, {
            weekday: 'short',
            month: '2-digit',
            day: '2-digit',
        });
    }
    async fetchSalesArchive() {
        if (!this.currentPlant || !this.currentPlant.plantId) return;

        this.$store.dispatch(SET_APP_LOADING, true);
        try {
            const _saList = (
                await fruttaMeClient.archive('it', this.currentPlant.plantId)
            ).data as SupplierArchiveTransportDTO[];
            _saList.sort((a, b) => {
                const dateA = a.loadDate
                    ? new Date(a.loadDate).getTime() || 0
                    : 0;
                const dateB = b.loadDate
                    ? new Date(b.loadDate).getTime() || 0
                    : 0;
                return dateB - dateA;
            });
            this.salesArchiveList = _saList;
        } catch (error) {
            console.error(error);
            this.$messageService.error(
                (error.data as IResult).errorMessage ?? this.$t('general.error')
            );
        } finally {
            this.$store.dispatch(SET_APP_LOADING, false);
        }
    }

    openTransport(transportId: number) {
        this.$router.push({
            name: PAGE_SALES_ARCHIVE_DETAIL,
            params: {
                transportId,
            },
        });
    }
}
