<template>
    <ion-page>
        <TheHeader />

        <ion-content>
            <div class="container">
                <h1 class="fs-20">
                    {{ $t("salesArchive.pagetitle") }}
                </h1>
                <ion-item color="primary" v-if="!salesArchiveList.length">
                    {{ $t("salesArchive.noElements") }}
                </ion-item>
                <ion-card
                    v-for="sale in salesArchiveList"
                    :key="sale.transportId"
                    @click="openTransport(sale.transportId)"
                    class="card-custom"
                >
                    <ion-card-header>
                        <div
                            class="
                                d-flex
                                align-items-center
                                border-bottom
                                pb-2
                                text-dark
                            "
                        >
                            <div class="w-100">
                                <strong>{{ formatDate(sale.loadDate) }}</strong>
                            </div>
                            <span class="badge badge-outline mr-2">
                                {{ sale.transportCode }}
                            </span>
                            <span class="fs-18 cursor-pointer" slot="end">
                                <ion-icon
                                    :ios="chevronForwardOutline"
                                    :md="chevronForwardSharp"
                                ></ion-icon>
                            </span>
                        </div>
                    </ion-card-header>
                    <ion-card-content>
                        <div class="d-flex text-dark">
                            <div>
                                <img
                                    :src="sale.productIconUrl"
                                    :alt="sale.productName"
                                />
                            </div>
                            <div class="w-100 pl-3">
                                <h1 class="fs-18">{{ sale.productName }}</h1>
                                <span
                                    class="
                                        badge
                                        text-lowercase text-white
                                        default-orange
                                    "
                                    :style="
                                        'background-color:' +
                                        sale.manufactureColor +
                                        ';'
                                    "
                                    >{{ sale.productManufacture }}</span
                                >&nbsp;
                                <span class="badge badge-outline">{{
                                    sale.productVariety
                                }}</span>
                            </div>
                            <div class="pl-3 pt-2 text-right">
                                <strong class="text-nowrap fs-14">
                                    {{ sale.quantity }}Kg
                                </strong>
                                <br />
                                <span class="text-nowrap fs-12">
                                    {{ sale.price }}€/Kg
                                </span>
                            </div>
                        </div>
                    </ion-card-content>
                </ion-card>
            </div>
        </ion-content>
    </ion-page>
</template>

<script lang="ts" src="./SalesArchive.ts"></script>

<style lang="scss" src="./SalesArchive.scss"></style>
